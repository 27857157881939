<template>
  <div class="bg-main d-flex flex-column h-100">
    <div class="d-flex justify-center mt-5">
        <div v-if="showSuccess" class="auth-card android large text-center mt-16">
          <h2 class="auth-card__title _mt-8">
            Письмо со ссылкой на тестирование отправлено на Вашу электронную почту.
          </h2>
        </div>
       <div v-else class="auth-card android large text-center">
        <h2 class="auth-card__title mb-8">Активировать бесплатный аккаунт</h2>
        <form @submit.prevent="createCandidateConfirm" class="mt-6">
            <div class="container">
            <div class="row">
                <div class="col-md-6">
                <v-text-field label="Фамилия *"
                                :value="editedItem.last_name"
                                @input="$v.editedItem.last_name.$model = upperFirst($event), onInput('last_name')"
                                :error-messages="lastnameErrors"
                                outlined />
                </div>
                <div class="col-md-6">
                <v-text-field label="Имя *"
                                :value="editedItem.first_name"
                                @input="$v.editedItem.first_name.$model = upperFirst($event), onInput('first_name')"
                                :error-messages="firstnameErrors"
                                outlined />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                <v-text-field label="Адрес эл. почты *"
                                v-model.trim="editedItem.email"
                                @input="clearError"
                                @blur="$v.editedItem.email.$touch()"
                                :error-messages="emailErrors"
                                outlined />
                </div>
                <div class="col-md-6">
                  <v-text-field label="Ссылка"
                                :value="$route.params.ref"
                                outlined readonly/>
                </div>
            </div>

            <div v-for="(err, ind) in errors.create" :key="ind" class="error--text">
                <template v-if="!editedItem.hasOwnProperty(err[0])">
                  <div>{{ err[1].toString().indexOf("undefined")!==-1 ? "Внутренняя ошибка сервера" : err[1]}}</div>
                  <!-- <div v-for="(e, i) in err[1]" :key="i">{{e}}</div> -->
                </template>
            </div>
          </div>

          <div class="row _mt-3">

            <div class="col mx-auto">
              <v-checkbox v-model="ppolicy"
                :error-messages="ppolicyError"
                label="С политикой обработки персональных данных согласен (-на)"
                @change="ppolicyError=''"
              />
              <div class="text-light font-sm">
                Нажимая кнопку «Активировать аккаунт, даю свое согласие на обработку персональных данных,
                указываемых мною при прохождении теста на данном сайте в порядке и целях, указанных в
                <a target="_blank" href="/personal_policy.html">Политике в отношении обработки персональных данных</a>
              </div>
            </div>
          </div>

          <div class="row mt-3">
              <div class="col-md-6 mx-auto">
                 <v-btn type="submit" class="w-100" x-large color="primary"
                          :loading="loading.create"
                          :disabled="loading.create || !ppolicy">
                    Активировать аккаунт
                  </v-btn>
              </div>
            </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import { upperFirst } from 'lodash';
import { firstnameValidation, lastnameValidation } from '@/common/validationHelpers';
import { EMAIL, FIRST_NAME, LAST_NAME } from '@/common/constants/validations';
import { MESSAGES } from '@/common/constants/errorMessages';

export default {
  validations: {
    editedItem: {
      last_name: { required, lastnameValidation, maxLength: maxLength(LAST_NAME.MAX_LENGTH) },
      first_name: { required, firstnameValidation, maxLength: maxLength(FIRST_NAME.MAX_LENGTH) },
      email: { required, email, maxLength: maxLength(EMAIL.MAX_LENGTH) },
    },
  },
  data: () => ({
    editedItem: {
      last_name: '',
      first_name: '',
      email: '',
    },
    ppolicy: false,
    ppolicyError: '',
    showSuccess: false,
  }),
  watch: {
  },
  computed: {
    ...mapGetters({
      loading: 'users/candidates/loading',
      errors: 'users/candidates/errors',
    }),
    // dialogCreateShow: {
    //   get() {
    //     return this.$store.getters['users/candidates/dialogCreateShow'];
    //   },
    //   set(val) {
    //     if (!val) {
    //       this.editedItem = cloneDeep(this.defaultItem);
    //       this.$v.$reset();
    //     }
    //     this.setDialogCreateShow(val);
    //   },
    // },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.last_name.$dirty) return errors;
      if (!this.$v.editedItem.last_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.last_name.lastnameValidation) errors.push(MESSAGES.INCORRECT_LASTNAME);
      if (!this.$v.editedItem.last_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(LAST_NAME.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'last_name')?.[1] ?? errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.first_name.$dirty) return errors;
      if (!this.$v.editedItem.first_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.first_name.firstnameValidation) errors.push(MESSAGES.INCORRECT_FIRSTNAME);
      if (!this.$v.editedItem.first_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(FIRST_NAME.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'first_name')?.[1] ?? errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      if (!this.$v.editedItem.email.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.email.email) errors.push(MESSAGES.INCORRECT_EMAIL);
      if (!this.$v.editedItem.email.maxLength) errors.push(MESSAGES.MAX_LENGTH(EMAIL.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'email')?.[1] ?? errors;
    },
  },
  methods: {
    ...mapActions({
      clearError: 'users/candidates/clearError',
      createCandidateByRef: 'users/candidates/createCandidateByRef',
    }),
    upperFirst,
    onInput() {
      if (this.errors.create.length) this.clearError();
    },
    async createCandidateConfirm() {
      if (this.loading.create) return;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      if (!this.ppolicy) {
        this.ppolicyError = ' ';
        // 'Чтобы активировать аккаунт, необходимо дать согласие на обработку персональных данных';
        return;
      }
      const form = {
        ...this.editedItem,
        referral_number: this.$route.params.ref,
      };
      const response = await this.createCandidateByRef(form);
      if (response?.status === 'success') {
        this.showSuccess = true;
      }
      // if (response.token) {
      //   console.log('2', response.token);
      //   this.$router.push(`/test/${response.token}`);
      // }
    },
  },
};
</script>
